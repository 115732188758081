import React, { useEffect, useState } from 'react';
import UnAuthNavDesktop from '../../../components/Navbar/UnAuthNavDesktop';
import 'swiper/css';
import isDesktop from '../../../isDesktop';
import {
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import styles from "./LabsPageDynamic.module.css";
import { useNavigate } from "react-router-dom";
import image1 from "../../../assets/SeoPageImages/lab-page/image1.png";
import image2 from "../../../assets/SeoPageImages/lab-page/image2.png";
import image5 from "../../../assets/SeoPageImages/lab-page/image5.png";
import { Helmet } from "react-helmet-async";
import { jwtDecode } from "jwt-decode";
import WrapperDesktop from "../../../components/Wrapper/WrapperDesktop";
import FooterDesktop from '../../../components/Footer/FooterDesktop';
import { Mousewheel, FreeMode } from 'swiper/modules';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import axios from 'axios';

const LabsPageDynamic = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const token = localStorage.getItem("token");
  const [isValidToken, setIsValidToken] = useState(false);
  const [labItems, setLabItems] = useState([]);
  const titleToURI = (title) => title.replaceAll(" ", "-").toLowerCase();

  useEffect(() => {
    checkTokenValidity();
  }, [token]);

  const checkTokenValidity = () => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsValidToken(true);
      } catch (error) {
        console.error("Invalid token:", error.message);
      }
    }
  };

  const fetchLabItems = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/labs-seo/labs/all`);
        setLabItems(response.data.data || []);
        console.log(response.data);

    } catch (error) {
        console.error("Error fetching learn items:", error);
    }
};

useEffect(() => {
    checkTokenValidity();
    fetchLabItems();
}, [token]);

const renderCards = (section) => {
    return labItems
        .filter(item => section === 'all' || item.category === section)
        .map(item => (
            <SwiperSlide key={item.learn_id} style={{ marginRight: 0 }}>
                <Card
                    onClick={() => navigate(`/lab-page/${titleToURI(item.title)}`)}
                    sx={{
                        width: '24.103vw',
                        height: '29.231vw',
                        backgroundColor: '#2E2D2D25',
                        border: '2px solid #333333',
                    }}>
                    <CardMedia
                        sx={{ height: '16.667vw' }}
                        image={item.image_url}
                        title={item.title}
                    />
                    <div className={styles.cardBottom}>
                        <CardContent 
                            sx={{
                                p: '1.26vw 1.026vw 0vw 1.026vw', '&:last-child': { pb: '0.5vw' },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '12vw'
                            }}
                        >
                            <div className={styles.cardContent}>
                                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                                <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: item.short_description }}></div>
                            </div>
                            <a
                                style={{ color: "#44CCFF", cursor: "pointer" }}
                                className={styles.readMore}
                            >
                                Read More &#8594;
                                <hr className={styles.readMeLine} />
                            </a>
                        </CardContent>
                    </div>
                </Card>
            </SwiperSlide>
        ));
};

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

//   const filteredData = cardData.filter((item) =>
//     item.title.toLowerCase().includes(searchQuery.toLowerCase())
//   );

  const isDesktopDevice = isDesktop();

  return (
    <>
    {isValidToken ? <WrapperDesktop /> : <UnAuthNavDesktop />}
    <div style={{ marginLeft: isValidToken ? '4vw' : 0 }}>
        <div className={styles.learnSidenav}>
            <a href="#all">All</a>
            <a href="#core-test">Core Test</a>
            <a href="#test-series">Test Series</a>
        </div>
        <div className={styles.pageWrapper}>
            <div id='all' className={isValidToken ? styles.partOneAuth : styles.partOne} style={{scrollMarginTop: '4.5vw'}}>
                <div className={styles.subHeadings}>All</div>
                <SwiperComponent
                    spaceBetween={isValidToken ? 45 : 23}
                    slidesPerView={3.2}
                    modules={[Mousewheel, FreeMode]}
                    mousewheel={{ enabled: true, forceToAxis: true }}
                    freeMode={true}
                >
                    {renderCards('all')}
                </SwiperComponent>
            </div>
            <div id='core-test' className={isValidToken ? styles.partThreeAuth : styles.partThree} style={{scrollMarginTop: '4.5vw'}}>
                <div className={styles.subHeadings}>Core Test</div>
                <SwiperComponent
                    spaceBetween={isValidToken ? 45 : 23}
                    slidesPerView={3.2}
                    modules={[Mousewheel, FreeMode]}
                    mousewheel={{ enabled: true, forceToAxis: true }}
                    freeMode={true}
                >
                    {renderCards('core-test')}
                </SwiperComponent>
            </div>
            <div id='test-series' className={isValidToken ? styles.partThreeAuth : styles.partThree} style={{scrollMarginTop: '4.5vw'}}>
                <div className={styles.subHeadings}>Test Series</div>
                <SwiperComponent
                    spaceBetween={isValidToken ? 45 : 23}
                    slidesPerView={3.2}
                    modules={[Mousewheel, FreeMode]}
                    mousewheel={{ enabled: true, forceToAxis: true }}
                    freeMode={true}
                >
                    {renderCards('test-series')}
                </SwiperComponent>
            </div>
        </div>
    </div>
    {!isValidToken && <FooterDesktop />}
</>
  );
}

export default LabsPageDynamic;
